<template>
    <div class="navbar">
        <div class="main">
            <a href="/" class="left">
                <img :src="HomeLogo" alt="fastorder_logo" />
            </a>

            <div class="right">
                <div class="nav-item" >Browse</div>
                <a href="/orders" class="nav-item" :style="page=='orders' ? 'color:#ffcf44': '' ">Orders</a>
                <a href="/favorites" class="nav-item" :style="page=='favorites' ? 'color:#ffcf44': '' ">Favourite</a>
                <a href="/notification" class="nav-item" :style="page=='notification' ? 'color:#ffcf44': '' ">Notification</a>
                <a href="/account" class="nav-item" :style="page=='account' ? 'color:#ffcf44': '' ">Account</a>
            </div>
        </div>
    </div>
</template>
<script>
import NavFooter from '@/components/common/NavFooter.vue'
import Loading from "@/view/Loading.vue";
import HomeLogo from '@/assets/logo2.png'

export default {
    name: "Navbar",
    data(){
        return{
            page:'',
            HomeLogo:HomeLogo
        }
    },
    created(){
        this.page= window.location.pathname.replace(/\//g, '')
    },
    methods:{

    }
}

</script>

<style lang="scss" scoped>
@import "@/components/common/css/global.scss";
@import "@/components/common/css/common.scss";

.navbar{
    background: #fff;
    box-shadow: 0 0 2px 0 #cccccc;
    @media screen and (max-width: $bp-mobile){
        display: none;
    }
}
.main{
    max-width: 1600px;
    margin: 0 auto;
    padding: 0 40px;
    height: 95px;
    display: flex;
    align-items: center;
    justify-content: space-between;

    @media screen and (max-width: 860px){
        height: 60px;
    }
}
.left{
    display: flex;
    align-items: center;
    img{
        height: 40px;
        @media screen and (max-width: 860px){
        height: 2rem;
    }
    }
}
.right{
    display: flex;
    align-items: center;
}
.nav-item{
    color: $red1;
    cursor: pointer;
    font-size: 1.1rem;
    font-weight: 600;
    margin-left: 0.8rem;

    @media screen and (max-width: 860px){
        font-size: 0.9rem;
        margin-left: 0.7rem;
        font-weight: 500;
    }
}

</style>