<template>
    <div>
        <Loading v-if="!requested" />
        <div class="favorites" v-if="requested" >
            <div class="main">
                <navbar />
                <div class="wrapper">
                    <div class="header">
                        <h1>Favorites</h1>
                    </div>
                    <div class="body">
                        <div class="favourite" v-for="n in 10" :key="n">
                            <div class="favor-img">
                                <v-card width="160">
                                    <v-img height="160" src="https://fomy-assets-sandbox.oss-ap-southeast-3.aliyuncs.com/images/2/2021/05/ahrqoFr0ltL0FFfekphfheL8gxir8h.jpg"></v-img>
                                </v-card>
                            </div>
                            <div class="favorite-detail">
                                <div class="favor-type">Japanese</div>
                                <div class="favor-food-name">Nigiri Sushi</div>
                                <div class="favor-restaurant">Sushi Today</div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>

        <NavFooter v-if="requested" />
    </div>
</template>
<script>
import NavFooter from '@/components/common/NavFooter.vue'
import Loading from "@/view/Loading.vue";
import Navbar from "./Navbar.vue"

export default {
    name: "Favorites",
    components:{
        NavFooter,
        Loading,
        Navbar
    },
    data(){
        return{
            requested: false,
        }

    },
    created(){
        setTimeout(()=>{
            this.requested=true;
        },300)
    },
    methods:{

    }
}

</script>

<style lang="scss" scoped>
@import "@/components/common/css/global.scss";
@import "@/components/common/css/common.scss";

.wrapper{
    max-width: 1600px;
    margin: 0 auto;
    padding: 0 40px 60px 40px;
    @media screen and (max-width: $bp-mobile){
        padding: 0 14px 60px 14px;
    }
}
.header{
    padding: 2rem 0 1rem 0;
}
.body{
    display: grid;
    grid-template-columns: repeat(auto-fill, minmax(170px,1fr));
}

.favourite{
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    padding: 1rem 0;
    border-bottom: 1px solid #e5e5e5;
}

.favorite-detail{
    margin-top: .8rem;
}
.favor-type{
    color: $gray4;
    font-size: 0.9rem;
}
.favor-food-name{
    font-size: 1.1rem;
    font-weight: 500;
}
.favor-restaurant{
    color: $gray4;
    font-size: 0.9rem;
}

</style>